/* eslint-disable */

<template>
  <div class="vx-row">
    <vx-card class="mb-base">
      <div class="fourth-sim pt-0">
        <div class="vx-row">
          <div class="vx-col w-1/2">
            <div class="border border-solid border-white p-5">
              <h1 class="mb-4">Command</h1>
              <div class="ubuntu-font">
                <h3 class="mb-2">[root@webserver log]# getenforce<br /></h3>
                <h3 class="mt-2 mb-2">[root@webserver log]# getsebool -a I grep httpd<br /></h3>
                <h3 class="mt-2 mb-2">[root@webserver log]# netstat -antp<br /></h3>
                <h3 class="mt-2 mb-2">[root@webserver log]# ss -antp<br /></h3>
                <h3 class="mt-2 mb-2">[root@webserver log]# ps -ef i grep nginx<br /></h3>
                <h3 class="mt-2 mb-2">[root@webserver log]# grep ave /var/log/audit/audit.log<br /></h3>
                <h3 class="mt-2 mb-2">[root@webserver log]# more /var/log/web/access_log<br /></h3>
                <h3 class="mt-2 mb-2">[root@webserver log]# more /var/log/web/error_log<br /></h3>
                <h3 class="mt-2 mb-2">[root@webserver log]# ls -la<br /></h3>
                <h3 class="mt-2 mb-2">[root@webserver log]# journalctl -u nginx -1<br /></h3>
                <h3 class="mt-2 mb-2">[root@webserver log]# systemctl status webserver<br /></h3>
                <h3 class="mt-2">[root@webserver log]# sealert<br /></h3>
              </div>
            </div>
          </div>
          <div class="vx-col w-1/2">
            <div class="border border-solid border-white p-5">
              <h1 class="mb-4">Answer</h1>
              <h3>[root@webserver log]#</h3>
              <v-select placeholder="Select An Answer" class="w-full mt-4" :options="answers" v-model="selected" />
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      answers: [
        { id: 1, label: 'systemctl restart nginx' },
        { id: 2, label: 'setsebool -P httpd_read_user_content -1' },
        { id: 3, label: 'restorecon -rv /home/comptia/html' },
        { id: 4, label: 'kill -HUP 3431' },
        { id: 5, label: 'sysctl -w net.ipv4 ip_forward = 1' },
        { id: 6, label: 'chown -R Comptia: /home/comptia/html/' },
        { id: 7, label: 'firewall-cmd --zone-public --service=http --permanent' },
        { id: 8, label: 'chmod 777 /home/comptia/html/' },
        { id: 9, label: 'setfacl -m g:comptia:rwx /home/comptia/html/' },
        { id: 10, label: 'cp -R /home/comptia/html /var/www/html' },
      ],

      selected: null,
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      if (this.selected && this.selected.id === 1) {
        totalScore++;
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.answers = shuffle(this.answers);
  },
};
</script>
